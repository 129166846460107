import request from '../utils/request'
import config from './config'

/**
 * 床位管理
 */
// 统计数据--c床位管理
export function getCount(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/getCount`,
        method: 'get',
        params
    })
}

// 统计数据--停发列表
export function getStopCount(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/getStopCount`,
        method: 'get',
        params
    })
}

// 床位建设分页查询
export function bedPage(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedPage`,
        method: 'post',
        data
    })
}

// 床位建设--详情--建设日志
export function checkBedLog(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/checkBedLog`,
        method: 'get',
        params
    })
}

// 床位建设--详情--建设清单
export function pageProject(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/service/pageProject`,
        method: 'post',
        data
    })
}

// 床位监管分页查询
export function bedSupervisePage(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedSupervisePage`,
        method: 'post',
        data
    })
}

// 停发列表分页查询
export function bedStopPage(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedStopPage`,
        method: 'post',
        data
    })
}

// 导出床位建设数据
export function exportAll(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedPage/exportAll`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 导出床位监管
export function superviseExportAll(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedSupervisePage/exportAll`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 导出停发列表
export function stopExportAll(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedStopPage/exportAll`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 床位签约列表
export function signPage(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/signPage`,
        method: 'post',
        data: data
    })
}

// 床位详情
export function userInfo(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/userInfo`,
        method: 'get',
        params
    })
}

// 导出床位签约数据
export function signExportAll(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionBed/bedSignPage/exportAll`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 风险事件列表
export function selectRiskEventList(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/selectRiskEventList`,
        method: 'post',
        data: data
    })
}

// 修改风险事件状态
export function updateRiskEventStates(data) {
    return request({
        url: `${config.BACK_HOST}/riskEvent/updateRiskEventStates`,
        method: 'post',
        data: data
    })
}

//下载风险事件
export function downloadRiskEvent(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/riskEventListPageDown`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 查询风险事件处理原因
export function getRiskEventReason(params) {
    return request({
        url: `${config.BACK_HOST}/data/data/riskEvent/getRiskEventReason`,
        method: 'get',
        params: params
    })
}

// 查询风险事件处理进度
export function getRiskEventProgress(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/getRiskEventProgress`,
        method: 'get',
        params: params
    })
}

// 查询风险事件通知进度
export function getRiskEventNoticeProgress(params) {
    return request({
        url: `${config.MAIN_HOST}/riskEvent/getRiskEventNoticeProgress`,
        method: 'get',
        params: params
    })
}
